.not_allowed_first_choose_curriculum {
    cursor: not-allowed;
}

.not_allowed_first_choose_curriculum:hover {
    background: none !important;
}

.not_allowed_curriculum {
    cursor: not-allowed !important;
}
