@import "/src/_styles/mixin";

.body-home-container {
  margin-top: 80px;
  padding: 32px 0;
}

.home-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  @include screen_mobile {
    padding: 0 16px;
  }

  .box-info {
    border: 1px solid #333;
    border-radius: 16px;
    padding: 24px;
    width: 50%;
    display: flex;

    @include screen_mobile {
      width: 100%;
    }
  }


  .box-info-agency {

    .main-info-agency {}

    .avatar-agency {
      margin-right: 16px;

      img {
        object-fit: cover;
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
    }

    .name-agency {
      font-size: 28px;
      font-weight: 700;
      padding-top: 10px;

      @include screen_mobile {
        padding-top: 0;
      }
    }

    .sub-info-agency {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-top: 24px;
      padding-left: 16px;

      .info-agency-item {
        display: flex;
        gap: 8px;
      }

    }
  }

  .box-link {
    flex-direction: column;
    .title-box-link {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 16px;
    }

    .container-link-text {
      background-color: #ededed;
      padding: 4px 8px;
      width: 100%;
      display: flex;
      align-items: center;
    }

    .link-text {
      line-break: anywhere;
    }

    .btn-copy-link {
      background-color: #fff;
      border: 1px solid #bfbdbd;
      position: relative;
      padding: 6px;

      @include screen_mobile {
        display: none;
      }
    }

    .copy-text {
      background-color: #121111;
      color: #fff;
      font-size: 14px;
      padding: 4px 8px;
      position: absolute;
      bottom: -30px;
      right: 50%;
      transform: translateX(50%);
      text-wrap: nowrap;
    }

    .btn-copy-link-mobile {
      background-color: #fff;
      border: 1px solid #bfbdbd;
      padding: 6px;
      align-self: center;
      margin-top: 8px;
      width: 120px;
      display: none;
      align-items: center;
      justify-content: center;
      border-color: #00B9B7;
      color: #00B9B7;

      svg {
        width: 24px;
        height: 24px;
        margin-right: 6px;
      }

      @include screen_mobile {
        display: flex;
      }
    }
  }
}