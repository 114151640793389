@import url('https://fonts.googleapis.com/css?family=Manjari:400,700&display=swap');

body {
  font-family: Manjari, sans-serif;
  font-size: 14px;
}

.calendar {
  width: 350px;
  margin: 0 auto;
  box-shadow: 0 2px 4px 0 rgba(21, 27, 38, .15);
  border-radius: 6px;
}

.header {
  display: flex;
  align-items: center;
  padding: 12px 12px 0 12px;
  border-radius: 6px 6px 0 0;
  background-color: #fff;
  color: #fff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.nav {
  border: 1px solid #fff;
  border-radius: 25px;
  outline: 0;
  background-color: transparent;
  cursor: pointer;
}

.datetime {
  margin: 0 auto;
  font-size: 18px;
  font-weight: bold;
  color: #00B9B7;
}

.week-container,
.day-container {
  display: flex;
  flex-wrap: wrap;
}

.week-cell{
  font-family: 'Myriadpro-SemiBold';
}
.week-cell,
.day-cell {
  flex: 0 0 calc(100% / 7 - 10px);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  cursor: pointer;
  margin: 5px;
}

.day-cell.active{
  background: #fff;
  border: 2px solid #00A79D!important;
  border-radius: 25px!important;
  color: #404041!important;
}

.img-check-calendar{
  display: none;
  position: absolute;
  top: 0;
  right: -4px;
}

.day-cell.complete .img-check-calendar{
  display: block;

}


.img-missing-calendar{
  display: none;
  position: absolute;
  top: 9px;
  right: 1px;
}

.day-cell.missing .img-missing-calendar{
  display: block;
}
.day-cell.complete .img-missing-calendar{
  display: none;
}
.day-cell.active_date .img-missing-calendar{
  display: none;
}
.day-cell.today .img-missing-calendar{
  display: none;
}

.img-active_dot-calendar{
  display: none;
  position: absolute;
  top: 0px;
  right: -5px;
}

.day-cell.missing .img-active_dot-calendar{
  display: none;
}
.day-cell.complete .img-active_dot-calendar{
  display: none;
}
.day-cell.active_date .img-active_dot-calendar{
  display: block;
}
.day-cell.today .img-active_dot-calendar{
  display: none;
}

.day-cell.today{
  border: none!important;
}

.day-cell {
  &--faded {
    opacity: .4;
  }

  &--today {
    border-radius: 25px;
    background-image: linear-gradient(to right, #00e1a0 , #00b9b7)!important;
    color: #fff!important;
    border: none;
  }
}
.lichngay-teacher .week-cell,.lichngay-teacher .day-cell {
  flex-basis: calc(100% / 7 - 36px);
  margin: 5px 0px;
}
.hide_collapse{
  display : none;
}
.fullHeight .hide_collapse{
  display : flex;
}
@media screen and (max-height: 800px) {
  .img-check-calendar {
    top: -4px;
    right: -2px;
  }
  .box-40-40.lichngay-teacher .day-cell {
    flex-basis: calc(100% / 7 - 24px);
    margin: 5px 0px;
  }
  .hide_collapse {
    display: none;
  }
}
@media screen and (max-height: 700px) {
  .calendar {
    // width: 258px;
    margin: 0 auto;
    box-shadow: 2px 3px 4px 0 rgba(21, 27, 38, .15);
    border-radius: 6px;
  }
  .week-cell,
  .day-cell {
    font-size: 14px;
    flex: 0 0 calc(100% / 7 - 10px);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 5px 0px;
  }
  .day-cell.missing .img-missing-calendar {
    display: block;
    width: 7px;
  }
  .img-missing-calendar {
    display: none;
    position: absolute;
    top: 2px;
    right: -3px;
  }
  .img-check-calendar {
    top: -4px;
    right: -4px;
  }
  .box-40-40.lichngay-teacher .day-cell {
    flex-basis: calc(100% / 7 - 27px);
    margin: 5px 0px;
  }
  .hide_collapse {
    display: none;
  }

  .homePageTeacherCalendaSchedule {
    margin: 0 !important
  }
}