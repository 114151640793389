.btn_custom {
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &:hover {
    opacity: 0.8;
  }
}
