@import "/src/_styles/mixin";

.register_step {


    @include screen_mobile {
        padding-top: 0;
    }

    .title_register {
        margin-bottom: 18px;

        @media screen and (max-width: 768px) {
            margin-bottom: 14px;
        }
    }

    .step_register_name {
        font-size: 24px;
        line-height: 28px;

        @media screen and (max-width: 768px) {
            font-size: 18px;
        }
    }

    .login_text {
        cursor: pointer;

        p,
        a {
            font-size: 18px;
        }

        &:hover {

            p,
            a {
                color: #35b5c3;
            }
        }
    }
}

.register_create_account {
    .step_register_name {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 36px;
    }
}

.register-form-container_content {
    max-width: 70%;
    margin: 0 auto;
    margin-top: 50px;

    @include screen_mobile {
        max-width: unset;
    }

    .register-content-form {
        gap: 32px;

        @include screen_mobile {
            flex-direction: column;
            gap: 0;
        }
    }

    .title {
        font-size: 32px;
        text-transform: uppercase;
        margin-bottom: 34px;

        @include screen_mobile {
            margin-bottom: 22px;
        }
    }

    .button_submit {
        display: flex;
        justify-content: center;
        margin: 24px 0;
    }

    .login_text {
        font-size: 18px;
        color: #00b9b7;
        font-weight: 600
    }
}

.register-side {
    flex: 1;

    .gender_female,
    .gender_male {
        margin-left: 16px;
    }

    .image-gender {
        width: 64px;
        height: 64px;

        @include screen_mobile {
            width: 44px;
            height: 44px;
        }
    }

    .radio-role {
        display: flex;
        align-items: center;
    }

    .radio-btn-role {
        width: 20px;
        height: 20px;
        margin: 0;
        margin-right: 6px;
    }

    input[type=radio] {
        appearance: none;
        border-radius: 50%;
        background-clip: content-box;
        border: 1px solid #000;
        background-color: #fff;
    }

    input[type="radio"]:checked {
        background-color: #00b9b7;
        padding: 2px;
        border: 2px solid #00b9b7;
    }

    .input_date_base_container {
        flex: 1;
    }
}

.login-form-container {
    padding-bottom: 40px;
}