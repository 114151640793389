$screen_tablet: 63.9375em;
$screen_mobile: 48em;

// Tablet
@media screen and (min-width: 48.01em) and (max-width: $screen_tablet) {
}

// Mobile
@media screen and (max-width: $screen_mobile) {
  // Display
  .display_block_mobile {
    display: block !important;
  }
  .display_flex_mobile {
    display: flex !important;
  }
  .display_none_mobile {
    display: none !important;
  }
  .display_inline_mobile {
    display: inline !important;
  }
  .display_inline_block_mobile {
    display: inline-block !important;
  }

  // MOBILE

  // Flex
  .flex-between-mobile {
    display: flex;
    justify-content: space-between;
  }
  .flex-align-end-mobile {
    display: flex;
    align-items: flex-end;
  }

  .flex-align-start-mobile {
    display: flex;
    align-items: flex-start;
  }

  .flex-jus-around-mobile {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .flex-jus-between-mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .flex-just-center-mobile {
    display: flex;
    justify-content: center;
  }

  .flex-jus-end-between-mobile {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  .flex-jus-start-between-mobile {
    display: flex;
    justify-content: space-between;
  }

  .flex-jus-evenly-mobile {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  .flex-align-mobile {
    display: flex;
    align-items: center;
  }

  .flex-center-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .flex-start-mobile {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .flex-end-mobile {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
  }

  .flex-between-row-mobile {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .flex-row-mobile {
    display: flex;
    flex-direction: row;
  }

  .flex-center-column-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .flex-column-mobile {
    display: flex;
    flex-direction: column;
    justify-content: flex-start !important;
  }
  .flex-row-mobile {
    display: flex;
    flex-direction: row !important;
  }

  .flex-row-reverse-mobile {
    display: flex;
    flex-direction: row-reverse !important;
  }

  // Col
  .col-max-100-mobile {
    max-width: 100% !important;
  }
  .col-100-mobile {
    width: 100% !important;
  }
  .col-90-mobile {
    width: 90% !important;
  }

  .col-20-mobile {
    width: 20% !important;
  }

  .col-25-mobile {
    width: 25% !important;
  }
  .col-75-mobile {
    width: 75% !important;
  }
  .col-80-mobile {
    width: 80%;
  }
  .col-82-5-mobile {
    width: 82.5%;
  }
  .col-50-mobile {
    width: 50% !important;
  }
  .col-40-mobile {
    width: 40% !important;
  }
  .col-45-mobile {
    width: 45% !important;
  }

  // FontSize

  // Mobile
  .fz-07-mobile {
    font-size: 0.7rem !important;
  }
  .fz-07-5-mobile {
    font-size: 0.75rem !important;
  }
  .fz-08-mobile {
    font-size: 0.8rem !important;
  }
  .fz-08-75-mobile {
    font-size: 0.875rem !important;
  }
  .fz-09-mobile {
    font-size: 0.9rem !important;
  }
  .fz-09-375-mobile {
    font-size: 0.9375rem !important;
  }

  .fz-14-mobile {
    font-size: 1.4rem !important;
  }
  .fz-15-mobile {
    font-size: 1.5rem !important;
  }
  .fz-16-mobile {
    font-size: 1.6rem !important;
  }
  .fz-18-mobile {
    font-size: 1.8rem !important;
  }
  .fz-17-mobile {
    font-size: 1.7rem !important;
  }

  .fz-20-mobile {
    font-size: 2rem !important;
  }
  .fz-21-mobile {
    font-size: 2.1rem !important;
  }
  .fz-21-5-mobile {
    font-size: 2.15rem !important;
    line-height: 3rem;
  }

  .fz-22-mobile {
    font-size: 2.2rem !important;
  }

  .fz-12-mobile {
    font-size: 1.2rem !important;
  }
  .fz-12-5-mobile {
    font-size: 1.25rem !important;
  }
  .fz-13-mobile {
    font-size: 1.3rem !important;
  }
  .fz-13-75-mobile {
    font-size: 1.375rem;
  }
  .fz-11-mobile {
    font-size: 1.1rem !important;
  }
  .fz-11-25-mobile {
    font-size: 1.125rem !important;
  }
  .fz-11-5-mobile {
    font-size: 1.15rem !important;
  }

  .fz-1-mobile {
    font-size: 1rem !important;
  }

  .fz-24-mobile {
    font-size: 2.4rem !important;
  }
  .fz-25-mobile {
    font-size: 2.5rem !important;
  }

  .fz-26-mobile {
    font-size: 2.6rem !important;
  }

  .fz-28-mobile {
    font-size: 2.8rem !important;
  }

  .fz-30-mobile {
    font-size: 3rem !important;
  }

  .fz-32-mobile {
    font-size: 3.2rem !important;
  }

  .fz-35-mobile {
    font-size: 3.5rem !important;
  }

  // Border
  .border-1-mobile {
    border: 1px solid var(--text-color);
  }

  .border-bottom-1-mobile {
    border-bottom: 1px solid var(--text-color);
  }
  .border-bottom-1-black-mobile {
    border-bottom: 1px solid var(--black-color);
  }
  .border-bottom-2-black-mobile {
    border-bottom: 2px solid var(--black-color);
  }

  .border-bottom-2-mobile {
    border-bottom: 2px solid var(--text-color);
  }

  .border-bottom-light-1-mobile {
    border-bottom: 1px solid var(--border-color);
  }
  .border-top-light-1-mobile {
    border-top: 1px solid #e6e6e6;
  }

  // Margin-Padding
  .pd-top-2 {
    padding-top: 2rem;
  }
  .pd-top-0-mobile {
    padding-top: 0rem !important;
  }

  .pd-vertical-0-3-mobile {
    padding-top: 0.3rem !important;
    padding-bottom: 0.3rem !important;
  }
  .pd-top-0-5 {
    padding-top: 0.5rem;
  }
  .pd-top-1 {
    padding-top: 1rem;
  }
  .pd-top-15 {
    padding-top: 1.5rem;
  }
  .pd-top-17-5 {
    padding-top: 1.75rem;
  }
  .pd-bottom-0-mobile {
    padding-bottom: 0rem !important;
  }
  .pd-bottom-1-mobile {
    padding-bottom: 1rem !important;
  }
  .pd-bottom-15-mobile {
    padding-bottom: 1.5rem !important;
  }

  .pd-right-4per {
    padding-right: 4%;
  }
  .pd-left-4per {
    padding-left: 4%;
  }

  // Margin
  .mg-bottom-2-mobile {
    margin-bottom: 2rem !important;
  }
  .mg-bottom-15-mobile {
    margin-bottom: 1.5rem !important;
  }
  .mg-bottom-12-mobile {
    margin-bottom: 1.2rem;
  }
  .mg-bottom-0-mobile {
    margin-bottom: 0 !important;
  }
  .mg-bottom-1-mobile {
    margin-bottom: 1rem !important;
  }
  .mg-bottom-0-5-mobile {
    margin-bottom: 0.5rem !important;
  }
  .mg-bottom-0-7-mobile {
    margin-bottom: 0.7rem;
  }
  .mg-bottom-0-9-mobile {
    margin-bottom: 0.9rem;
  }

  .mg-top-2-mobile {
    margin-top: 2rem !important;
  }
  .mg-top-1-mobile {
    margin-top: 1rem;
  }
  .mg-top-15-mobile {
    margin-top: 1.5rem;
  }
  .mg-top-0-5-mobile {
    margin-top: 0.5rem;
  }

  .mg-right-0-mobile {
    margin-right: 0rem !important;
  }

  // Form Input
  .form_input_mobile {
    .input_text_base,
    .input_date_base,
    .input_select_main_wrapper {
      padding: 0 2vw !important;
    }
    .drop_down {
      top: 3.3rem !important;
    }
    .icon_dropdown {
      width: 1.2rem !important;
      height: auto;
    }
  }

  // Color
  .black-color-mobile {
    color: var(--black-color) !important;
  }

  .animation_slidein_top {
    animation: slideTopIn linear 0.3s;
  }

  // Color
  .color-text-mobile {
    color: var(--text-color) !important;
  }

  .color-light-text-mobile {
    color: #6e6e6e !important;
  }

  .text-align-center-mobile {
    text-align: center;
  }

  // Font
  .font_news_semi_bold_mobile {
    font-family: "AvertaStdCY-SemiBold" !important;
  }
  .font_news_bold_mobile {
    font-family: "AvertaStdCY-Bold" !important;
  }
  .font_news_extra_bold_mobile {
    font-family: "AvertaStdCY-ExtraBold" !important;
  }

  .font_news_black_mobile {
    font-family: "AvertaStdCY-Black" !important;
  }

  .font_news_mobile {
    font-family: "AvertaStdCY" !important;
  }

  // Position
  .pos_rel_mobile {
    position: relative !important;
  }
}
