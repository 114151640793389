@import "/src/_styles/mixin";

$green: #00b9b7;
$bg-color: #f3ffff;
$bg-fb: #1b75bb;
$bg-gg: #be1e2d;
$bg-apple: #000000;
$loading-color: #f8f8f8;

.login-form-container {
  input[type="checkbox"] {
    -moz-appearance: initial; // Hack for Firefox Browsers
  }

  &_content {
    margin: 0 auto;
    margin-top: 50px;
    max-width: 450px;

    // @media screen and (max-height: 800px) {
    //   margin-top: -145px;
    // }

    .title {
      font-size: 32px;
      text-transform: uppercase;
      margin-bottom: 34px;

      @include screen_mobile {
        margin-bottom: 22px;
      }
    }

    .password_options {
      align-items: center;

      label,
      a {
        font-size: 18px;
      }

      label {
        position: relative;
        top: 2px;
      }

      .save_password {
        &_checkbox {
          position: relative;
          margin: -5px 17px 0 2px;
          width: 16px;
          height: 16px;
          border: 1px solid $green;
          border-radius: 4px;
          cursor: pointer;

          &:before {
            content: "";
            display: block;
            position: absolute;
            width: 20px;
            height: 20px;
            top: -2px;
            left: -2px;
            border: 1px solid $green;
            border-radius: 5px;
            background-color: white;
          }

          &:checked:before {
            background-color: $green;
          }

          &:checked:after {
            content: "";
            display: block;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            position: absolute;
            top: 1px;
            left: 6px;
          }
        }
      }
    }

    .button_submit {
      margin-top: 26px;
      margin-bottom: 40px;
      text-align: center;

      button {
        height: 50px;
        width: 210px;
        border-radius: 25px;
        font-size: 18px;
        line-height: unset;
      }
    }

    .line_or {
      overflow: visible;
      text-align: inherit;
      margin: 0 0 20px 0;
      border: 0;
      border-top: 1px solid #777777;
      position: relative;

      span {
        width: 70px;
        background: #f3ffff;
        position: absolute;
        display: block;
        top: -12px;
        left: calc(50% - 40px);
        text-align: center;
        font-size: 18px;
      }
    }

    .social_login {
      display: flex;
      margin-top: 32px;

      &_item {
        flex: 1;
        margin-right: 15px;
        cursor: pointer;

        &:last-child {
          margin-right: 0px;
        }
      }

      &_container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 16px;
        height: 50px;
        border-radius: 10px;

        // &.facebook {
        //     background-color: $bg-fb;
        // }
        &.google {
          background-color: $bg-gg;
        }

        &.apple {
          background-color: $bg-apple;
        }

        .icon {
          margin-right: 9px;
        }

        span {
          color: white;
          font-size: 18px;
        }
      }
    }
  }

  .register_text {
    margin-top: 25px;

    p,
    a {
      font-size: 18px;
      color: $green;
      font-weight: 600;
    }
  }

  .resend_email {
    margin-top: 5px;

    span {
      color: #b02343;
      text-decoration: underline;
      font-size: 18px;
      cursor: pointer;
      font-weight: 600;
    }
  }

  form {
    position: relative;

    .login_error_server {
      // position: absolute;
      // bottom: calc(100% + 18px);
      background-color: #fdf4f4;
      padding: 26px 34px;
      margin-bottom: 30px;
      width: 100%;
      border-radius: 10px;

      p {
        font-size: 18px;
        color: #b02343;
      }
    }
  }

  .fb_disabled {
    cursor: no-drop;

    .loading_fb {
      width: 100%;
      display: flex;
      justify-content: center;

      .dot-pulse {
        position: relative;
        left: -9999px;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: $loading-color;
        color: $loading-color;
        box-shadow: 9999px 0 0 -5px $loading-color;
        animation: dotPulse 1.5s infinite linear;
        animation-delay: 0.25s;
      }

      .dot-pulse::before,
      .dot-pulse::after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 0;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: $loading-color;
        color: $loading-color;
      }

      .dot-pulse::before {
        box-shadow: 9984px 0 0 -5px $loading-color;
        animation: dotPulseBefore 1.5s infinite linear;
        animation-delay: 0s;
      }

      .dot-pulse::after {
        box-shadow: 10014px 0 0 -5px $loading-color;
        animation: dotPulseAfter 1.5s infinite linear;
        animation-delay: 0.5s;
      }

      @keyframes dotPulseBefore {
        0% {
          box-shadow: 9984px 0 0 -5px $loading-color;
        }

        30% {
          box-shadow: 9984px 0 0 2px $loading-color;
        }

        60%,
        100% {
          box-shadow: 9984px 0 0 -5px $loading-color;
        }
      }

      @keyframes dotPulse {
        0% {
          box-shadow: 9999px 0 0 -5px $loading-color;
        }

        30% {
          box-shadow: 9999px 0 0 2px $loading-color;
        }

        60%,
        100% {
          box-shadow: 9999px 0 0 -5px $loading-color;
        }
      }

      @keyframes dotPulseAfter {
        0% {
          box-shadow: 10014px 0 0 -5px $loading-color;
        }

        30% {
          box-shadow: 10014px 0 0 2px $loading-color;
        }

        60%,
        100% {
          box-shadow: 10014px 0 0 -5px $loading-color;
        }
      }
    }
  }

  .login_social_wrapper {
    margin: 0px 120px;
  }
}

.hide_step_login {
  display: none;
}