@import "/src/_styles/mixin";

.header-main-container {
  box-shadow: 1px 2px 4px 0 rgba(21, 27, 38, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  background-color: #fff;

  @include screen_mobile {
    padding-right: 16px;
  }

  .logo {
    img {
      width: 120px;
      margin: 0;
      padding: 0;
    }
  }

  .header-main-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;

    .btn-logout {
      border-radius: 50%;
      border: 2px solid #00B9B7;
      padding: 6px;
      cursor: pointer;
      position: relative;
      width: 36px;
      height: 36px;
      display: flex;

      .btn-logout-text {
        font-size: 14px;
        color: #000;
        position: absolute;
        display: inline block;
        text-wrap: nowrap;
        bottom: -30px;
        right: 50%;
        transform: translateX(50%);
        background-color: #fff;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        padding: 2px 8px;
        border-radius: 24px;
      }
    }
  }
}